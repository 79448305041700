<template>
    <div class="">
        <layout>
            <template v-slot:header>
                <div class="">
                    <div class="expeditedservice-mainblock w-100 index p-relative">
                        <v-container class="mb-6">
                            <breadcrumbs :items="breadcrumbs" :large="true" class="my-5" :textcolor="`textwhite`"></breadcrumbs>
                            <h1 class="heading-1 white--text">Expedited Shipping</h1>
                            <p class="white--text my-10 d-none d-sm-block">This transportation service gives your cargo a special shipment <br/>priority over others, making the delivery process faster</p>
                            <div class="text-20-60 white--text">Get instant quotes</div>
                            <v-btn v-if="!showexpansion && $vuetify.breakpoint.xs" x-large class="secondary px-10 py-4 mt-5 text-transform-none" @click="showexpansion = !showexpansion" depressed>Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
                            <v-row v-if="showexpansion || !$vuetify.breakpoint.xs" class="mt-5">
                                <v-col cols="12" sm="4" lg="3">
                                    <label class="white--text" for="">Pickup city or postal code</label>
                                    <v-autocomplete
                                        v-model="form.from.geoModel"
                                        :items="form.from.geoItems"
                                        :search-input.sync="form.from.geoSearch"
                                        background-color="#EDF2F9"
                                        outlined
                                        class="mt-2"
                                        hide-no-data
                                        hide-selected
                                        item-text="label"
                                        placeholder="Pickup city"
                                        hide-details
                                        return-object
                                    >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" sm="4" lg="3">
                                    <label class="white--text" for="">Requested loading date</label>
                                    <v-menu
                                        v-model="form.from.datepicker"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="computedDateFormatted"
                                                class="mt-2"
                                                outlined
                                                background-color="#EDF2F9"
                                                placeholder="08/05/2020"
                                                append-icon="mdi-calendar-month"
                                                hide-details
                                                v-bind="attrs"
                                                v-on="on"
                                                hide-details
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="form.from.date"
                                            no-title
                                            @input="form.from.datepicker = false"
                                        ></v-date-picker>
                                    </v-menu>

                                </v-col>
                            </v-row>
                            <v-btn  v-if="showexpansion || !$vuetify.breakpoint.xs" x-large class="secondary px-15 py-8 mt-5 text-transform-none" depressed @click="assign_and_redirect">Get quotes <v-icon>mdi-chevron-right</v-icon></v-btn>
                        </v-container>
                    </div>
                    <div class="agentpage-footerblock w-100 pt-16 pb-8 d-none d-md-block">
                        <v-container>
                            <v-list class="transparent mx-auto" flat >
                                <v-list-item-group class="d-flex flex-row align-self-center justify-space-between h-100">
                                    <v-list-item class="pl-0" :ripple="false">
                                        <v-list-item-content>
                                            <v-list-item-title class="text-center text-20-60 d-flex align-center">
                                                <img src="@/assets/icons/svg/star-white-rounded.svg" alt="Expedited shipping Icon" class="mr-5">
                                                <span class="border white--text">Door-to-door service</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item :ripple="false">
                                        <v-list-item-content>
                                            <v-list-item-title class="text-center text-20-60 d-flex align-center">
                                                <img src="@/assets/icons/svg/star-white-rounded.svg" class="mr-5">
                                                <span class="border white--text">Guaranteed delivery</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item :ripple="false">
                                        <v-list-item-content>
                                            <v-list-item-title class="text-center text-20-60 d-flex align-center">
                                                <img src="@/assets/icons/svg/star-white-rounded.svg" class="mr-5">
                                                <span class="border white--text">Fast delivery</span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-container>
                    </div>
                    <v-img class="expeditedservice-mainimage d-none d-md-block" src="@/assets/icons/services/expedited.svg" alt=""></v-img>
                </div>
            </template>
            <template v-slot:main>
                <v-container class="py-16">
                    <how-work :item="how_work"/>
                </v-container>
                <v-container class="py-16">
                    <h1 class="heading-2 mb-10">Expedited <br/>Shipping Benefits</h1>
                    <v-row>
                        <v-col cols="24" sm="6" lg="3" v-for="item in star_card">
                            <star-card :color="item.bg" :white="item.white">
                                <template v-slot:title>
                                    {{item.title}}
                                </template>
                                <template v-slot:description>
                                    {{item.text}}
                                </template>
                            </star-card>
                        </v-col>
                    </v-row>
                </v-container>
                <base-counter :bgimg="require('@/assets/logo/bg-logo-blue-full.svg')" :items="counter_items">
                    <h1 class="heading-2 white--text mb-10">We have a solid <br/> experience of expedited shipping</h1>
                </base-counter>
                <div class="py-16">
                    <faq-block :data="faq_data"/>
                </div>
                <div class="py-16">
                    <v-container>
                        <h1 class="heading-2">Other Services</h1>
                        <v-row>
                            <v-col cols="12" lg="4" v-for="item in other_services">
                                <service-card :data="item" />
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
                <div class="contact primary py-16">
                    <v-container class="my-6">
                        <contact-us />
                    </v-container>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
import Layout from '@/components/layouts/BlueTopLayout.vue'
import Breadcrumbs from '@/components/base/Breadcrumbs.vue'
import HowWork from '@/components/pages/HowWork.vue'
import StarCard from '@/components/base/StarCard.vue'
import BaseCounter from '@/components/base/BaseCounter.vue'
import FaqBlock from '@/components/pages/FAQ.vue'
import ServiceCard from '@/components/base/ServiceCard'
import ContactUs from '@/components/pages/ContactUs.vue'

import geodataMixin from '@/mixins/geodataMixin'

export default {
    mixins: [geodataMixin],
    components: {
        Layout,
        Breadcrumbs,
        HowWork,
        StarCard,
        BaseCounter,
        FaqBlock,
        ServiceCard,
        ContactUs
    },
    data: () => ({
        showexpansion: false,
        calculator: {
            zip: '',
            date: new Date().toISOString().substr(0, 10),
            dateFormatted: new Date().toISOString().substr(0, 10),
            datepicker: false,
        },
        breadcrumbs: [
            {
                text: 'Home page',
                to: 'frontend.home',
                disabled: false,
            },
            {
                text: 'Expedited Shipping',
                disabled: true,
            }
        ],
        how_work: {
            title: 'Expedited Shipping: <br /> How Does it Work',
            subtitle: 'Sometimes you may need to ship a relatively small freight. For this type of goods, Freight Crew provides the option of less-than-truckload shipping which is best for transporting small freight or when the load doesn’t require the use of an entire trailer',
            list: [
                {
                    icon: require('@/assets/icons/svg/hwork/msg.svg'),
                    text: 'Submit your request and enter the shipment details',
                },
                {
                    icon: require('@/assets/icons/svg/hwork/dir.svg'),
                    text: 'We examine your request and propose several effective solutions',
                },
                {
                    icon: require('@/assets/icons/svg/hwork/truck.svg'),
                    text: 'You choose the most suitable solution and we provide the carrier',
                },
                {
                    icon: require('@/assets/icons/svg/hwork/box.svg'),
                    text: 'Your freight is delivered',

                },
            ]
        },
        star_card: [
            {
                title: 'Door-to-door service',
                text: 'Door-to-door service with in-house customs clearance included',
                bg: 'bg-transparent'
            },
            {
                title: 'Guaranteed delivery',
                text: 'We guarantee the cargo safety condition',
                bg: 'bg-transparent'
            },
            {
                title: 'Fast delivery',
                text: 'Timely delivery even in the most outlying regions',
                bg: 'bg-transparent'
            },
            {
                title: 'Flexibility',
                text: 'Efficient delivery of any freight type ',
                bg: 'bg-blue',
                white: 'white--text'
            },

        ],
        counter_items: [
            {
                quantity: 50,
                unit: 'k',
                text: 'Over 50 k of loyal <br/> clients trust us',
            },
            {
                quantity: 3,
                unit: 'b',
                text: 'We shipped over 2 675 186 586 <br/> pounds of various freights',
            },
            {
                quantity: 2,
                unit: 'm',
                text: 'We delivered over 2 millions <br/>of expedited shipments',
            },
        ],
        faq_data: {
            title: 'Frequently Asked Questions about Expedited shipping',
            text: 'Please, read the FAQs for the expedited shipping. If you have any additional questions, don’t hesitate to contact us info@freightcrew.us',
            items: [
                {
                    title: 'What if I have a  time-sensitive shipment?',
                    text: 'Expedited shipping is one of the best solutions for the time-critical cases, as these services minimize the transit time and guarantee the freight safety. When you have an urgent shipment, and choose expedited shipping it gives your cargo a special shipment priority over others, making the delivery process faster. Our network of national, regional, and local carriers provides clients with quick access to various expedited freight shipping rates and the fastest routes. Choosing the expedited shipping you don’t waste time and skip unnecessary delays by streamlining the delivery process and route. Typically we send your cardo by air to reach the destination as soon as possible but may also involve other modes of transport that will ensure it arrives on time. '
                },
                {
                    title: 'How fast can you ship my cargo?',
                    text: 'The expedited shipping service meets your deadline and find the most effective means to move your freight just-in-time. So, if it usually takes around 5 days to receive your shipment, the average expedited shipping time is between 2-3 days. Thus, it will save you at least a day or two when compared to standard shipping. However, the other factors may also influence the time of delivery, such as distance, type of cargo, shipping options, etc.'
                },
                {
                    title: 'How much the expedited shipping cost?',
                    text: 'The cost of expedited services varies depending on the carrier type, destination, and weight. Also, the rate may significantly vary based on seasonality, dimensional weight, package weight, products shipped, and a variety of other factors. Please consider that the expedited shipping rates can run higher than standard rates because of its limited timeline.'
                },
                {
                    title: 'How do track my expedited shipping?',
                    text: 'When choosing the expedited shipping service we allow you to track your shipment via the Online Tracking System. Thus you can always check where your shipment is and get ahead of any problems that may arise.'
                },
            ]
        },
        other_services: [
            {
                img: 'svg/shipping/ltl-blue.svg',
                title: 'LTL Shipping',
                text: 'Less-than-truckload shipping or less than load (LTL) is the shipping of relatively small freight',
                to: 'frontend.services.ltl'
            },
            {
                img: 'svg/shipping/international-blue.svg',
                title: 'International shipping',
                text: 'Cost-effective international freight shipping operating in the United States, Canada, and Mexico',
                to: 'frontend.services.international'
            },
            {
                img: 'svg/shipping/airplane-blue.svg',
                title: 'Airfreight',
                text: 'Airfreight shipping services are fast and efficient that makes it so valuable to companies',
                to: 'frontend.services.airfreight'
            }
        ]
    }),
    computed: {
        computedDateFormatted () {
            return this.formatDate(this.calculator.date)
        },
    },
    watch: {
        'calculator.date' (val) {
            this.calculator.dateFormatted = this.formatDate(this.calculator.date)
        },
    },
    methods: {
        assign_and_redirect(){
            this.calculator_assign();
            this.calculator_redirect();
        },
        calculator_assign(){
            this.$store.dispatch('calculator/setForm', {
                form: this.form
            });
        },
        calculator_redirect(){
            this.$router.push({name: 'frontend.calculator'})
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
    }
}
</script>

<style lang="scss">
.primary.header{
    position: relative;
    overflow: hidden;
    header{
        z-index: 1;
    }
    .v-btn.v-size--x-large{
        font-weight: 450;
        font-size: 14px;
        line-height: 22px;
        text-transform: none;
    }
    .expeditedservice-mainimage{
            position: absolute;
            top: 0;
            right: 0;
    }
}
</style>
